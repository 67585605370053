import styled from "styled-components";
import { theme } from "../../styles/Styles";

type Props = {
  dark?: Boolean;
};
const StyledLogo = styled.div<Props>(
  ({ theme, dark }) => ({
    margin: 0,
    color: dark ? theme.accent : theme.blue,
    border: `2px solid ${dark ? theme.accent : theme.blue}`,
    transition: "all 0.3s",
    fontSize: "1rem",
    display: "flex",
    flexDirection: "row",
    padding: "5px 10px",
  }),
  `p {
    margin: 0;
    text-align: center;
  }
  hr {
    margin: 0 2px;
    height: 0;
    border: none;
    border-top: 2px solid ${theme.light.blue};
}
`
);

export const Logo = ({ dark }: { dark?: any }) => (
  <StyledLogo dark={dark}>
    <p>BEST DEVELOPMENTS</p>
  </StyledLogo>
);
