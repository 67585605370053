import styled from "styled-components";
import { VBox } from "../../styles/VBox";
import Section from "../../components/Section";
import { Box } from "../../styles/Box";
import { HBox } from "../../styles/HBox";
import Community from "./Community";
import Form from "./Form";
import { ChevronDown, EnvelopeFill } from "react-bootstrap-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { H1 } from "../../styles/Text";
import { Button } from "../../styles/Button";
import { theme } from "../../styles/Styles";
import { useState } from "react";

const Vid = styled.video`
  min-height: 100%;
  height: auto;
  object-fit: cover;
  object-position: 50% 0;
  margin-top: -10px;
  @media screen and (min-width: ${({ theme }) => `${theme.tablet}`}) {
    top: 0px;
  }
`;

const Skyline = styled(Vid)`
  height: 95vh;
  width: 100%;
  left: 0;
  z-index: 2;
  background: url("/skyline.jpg");
  background-size: cover;
  background-position: 50% 0;
`;

const StyledVBox = styled(VBox)`
  width: 100%;
  height: 30vh;
  padding-top: 50px;
  z-index: 10;
  background-size: cover;
  transition: none;
  position: absolute;
  top: 0;
  background: linear-gradient(180deg, ${theme.light.accent}, transparent);
  place-content: flex-start;

  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 10px;
    }
    50% {
      bottom: 0;
    }
  }

  h1 {
    font-size: 4.6pc;
    padding: 0 40px;
    text-transform: uppercase;
    font-weight: 100;
    z-index: 110;

    ${({ theme }) => `
    b {
      font-weight: 600;
    }
    color: ${theme.blue};
    margin: 0;
    margin-top: 20px;
  `}
  }
  p {
    color: white;
    font-size: 1pc;
    z-index: 110;
    padding: 0 40px;
  }
`;

const StyleVBox = styled(VBox)``;
const HomePage = (): JSX.Element => {
  let bubbles: JSX.Element[] = [];
  const [loading, setLoading] = useState<boolean>(true);

  const handleVideoLoaded = () => {
    setLoading(false);
  };
  const addBubbles = (): JSX.Element[] => {
    for (let i = 0; i < 20; i++) {
      bubbles.push(<div className="bubble" key={i} />);
    }
    return bubbles;
  };
  return (
    <StyleVBox
      style={{
        gap: "2rem",
        width: "-webkit-fill-available",
        scrollBehavior: "smooth",
      }}
    >
      <Skyline
        data-aos="zoom-out"
        autoPlay
        muted
        loop
        id="video-background"
        onLoadedData={handleVideoLoaded}
      >
        {/* <source src="timelapse.mp4" type="video/mp4" /> */}
      </Skyline>
      <StyledVBox>
        {addBubbles()}
        <VBox>
          <h1 data-aos="fade-down">
            Simply <b>Better</b>
          </h1>
        </VBox>
      </StyledVBox>
      {/* <Section head="Mission">
        <VBox style={{ gap: "1rem" }} id="first">
          <H1>WE MAKE THE CHOICE SIMPLE</H1>
          <p style={{ maxWidth: "800px" }}>
            At Best Developments, we respect and consider the opinions of our
            Architects, Designers and Fung Shui professional; realizing that
            each team has valuable insight into what it takes to design and
            construct a the perfect home. When it comes to making the best
            decision on which home is right for you, materials choices and
            finishing details are selected to enhance your family’s living
            experience and to meet or exceed the comfort levels you expect and
            deserve. Best Homes is <br />
            “Simply the Best."
          </p>
        </VBox>
      </Section> */}
      <Section head="Current Communities">
        <HBox flexWrap="wrap" style={{ gap: "2rem" }}>
          <Community
            src="/current.jpg"
            link="https://lakesideviews.ca"
            Name="The Lakeside Views"
            Description="199 Freehold homes situated in Beaverton minutes away from the lake."
            MoreDesc={
              <p>
                Settled lakeside in the charming community of Beaverton, also
                known as the largest region in the Brock Township. Enjoy
                year-round recreational activities along the stunning rolling
                countryside, surrounded by shoreline parks and in the midst of a
                quaint suburban area. Reside alongside Lake Simcoe, a key
                destination to enjoy all four seasons and relish in outdoor
                activities including swimming, canoeing, ice fishing, camping
                and much more! This delightful family-oriented community
                contains a mixture of Bungalows, 2-Storey & Towns starting from
                the <b>$600's</b>. Don't miss out on this incredible opportunity
                to enjoy an easy life of relaxation and bliss!
                <br />
                <br />
                <a
                  style={{ color: theme.light.blue }}
                  href="https://lakesideviews.ca"
                >
                  Visit →
                </a>
              </p>
            }
          />
          <Community
            src="/bobcaygeon.jpg"
            link="https://www.3lakes.ca/"
            Name="The Stars of Bobcaygeon"
            Description="Managed by 3Lakes, we bring you a stunning new subdivision situated in the Bobcaygeon area near the lake and beautiful scenery."
            MoreDesc={
              <p>
                From the Algonquin word ‘bobcaygewanunk’ meaning ‘shallow
                rapids’, Bobcaygeon is all about water. Under an hour drive from
                the GTA, set on three islands between Pigeon Lake and Sturgeon
                Lake, Bobcaygeon in Kawartha Lakes is where families have headed
                in the summertime for over a century. Imagine living here and
                enjoying the natural splendour, outdoor recreation and active
                lifestyle all year round. Get on the water in many ways; boating
                the Trent Severn Waterway, swimming, fishing, canoeing,
                waterskiing, or relaxing on a dock or lakeside café. Enjoy life
                in Bobcaygeon today, one of Ontario’s most beautiful waterfront
                communities with a quaint, resort-town feel, right in the heart
                of cottage country. Own an elegant new home in a brand new
                community, walking distance to vibrant historic Downtown
                Bobcaygeon where you’ll find a friendly welcome and small town
                charm. All the amenities are nearby including shopping,
                antiquing, dining, medical services as well as a seasonal
                Farmer’s Market and the famous Kawartha Dairy. Follow the Stars
                to Bobcaygeon and discover the place for you.
                <br />
                <br />
                <a
                  style={{ color: theme.light.blue }}
                  href="https://www.3lakes.ca/"
                >
                  Visit →
                </a>
              </p>
            }
          />
          <Community
            src="/sheridan.jpg"
            Name="Springside Meadows"
            link="https://springsidemeadows.ca"
            Description="An exceptional new subdivision offering consisting of 486 units in the Greater Napanee Area which is just 20 minutes away from Kingston."
            MoreDesc={
              <p>
                Springside Meadows is a new home community offering over 15
                acres of parks, ponds and passive green open spaces. A community
                for your lifestyle, less than 1km to the vibrant shops,
                restaurants, parks, riverside trails and choice fishing in the
                town of Napanee. A location that is almost 30km’s to either the
                City of Kingston, Belleville or to Prince Edward County (famous
                wineries and beaches).
                <br />
                <br />
                <a
                  style={{ color: theme.light.blue }}
                  href="https://springsidemeadows.ca"
                >
                  Visit →
                </a>
              </p>
            }
          />
        </HBox>
      </Section>

      <Section head="Communities">
        <HBox flexWrap="wrap" style={{ gap: "2rem" }}>
          <Community
            src="/past.jpg"
            Description="Communities which we have been a part of in the past!"
            fade="Past"
            link="communities/past-communities"
            disabled
          />
          <Community
            src="/future.jpg"
            Description="Upcoming cool and exciting projects!"
            fade="Future"
            link="communities/future-communities"
            disabled
          />
        </HBox>
      </Section>
      <Section head="Say Hello!">
        <HBox flexWrap="wrap" style={{ gap: "2rem" }}>
          <VBox style={{ gap: "2rem" }}>
            <H1>Speak to our team</H1>
            <p>
              Our goal at Best Developments is to ensure that every customer is
              looked after in the best way.
            </p>
            <Button
              intent="edit"
              icon={<EnvelopeFill />}
              onClick={() =>
                (window.location.href = "mailto:paula@besthomescanada.com")
              }
            >
              Email
            </Button>
            <Form />
          </VBox>
        </HBox>
      </Section>
    </StyleVBox>
  );
};

export default HomePage;
