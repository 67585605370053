import Banner from "../../components/Banner";
import Section from "../../components/Section";
import { HBox } from "../../styles/HBox";
import { VBox } from "../../styles/VBox";
import Community from "../HomePage/Community";
import { theme } from "../../styles/Styles";
import styled from "styled-components";
import { H1 } from "../../styles/Text";
import { Button } from "../../styles/Button";

const StyledVBox = styled(VBox)`
  padding: 40px;
  gap: 2rem;
  button {
    color: white;
    padding: 15px 30px;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    ${({ theme }) => `
    background: ${theme.blue}`}
  }
  p {
    @media screen and (max-width: ${({ theme }) => `${theme.tablet}`}) {
      font-size: 1.3rem;
    }
    max-width: 900px;
    b {
      font-weight: 600;
    }
  }
`;

const Partners = () => {
  return (
    <Section head="Our Family">
      <VBox
        style={{
          gap: "2rem",
          marginBottom: "-20px",
        }}
      >
        <Banner
          src="/niagra.jpg"
          heading="Partners"
          subHeading="Meet the best developments family!"
          anim
        />
        <HBox flexWrap="wrap" style={{ gap: "2rem" }}>
          <Community src="/bash.png" disabled />
          <StyledVBox style={{ paddingTop: "5px" }}>
            <H1>Bash Developments</H1>
            <p>
              The BASH Developments Group Inc. is a privately held company with
              substantial land holdings which develops and operates an extensive
              cross-section of residential, retail, commercial, and income
              properties. With more than 5 years of experience building dynamic
              communities in Western and Eastern Ontario, The BASH Development
              Group Inc. has evolved into one of the province’s largest growing
              land developers.
            </p>
            <p>
              The team has been working diligently to create an exceptional new
              home community in Napanee with the pending release of Springside
              Meadows. This is not a singular development for the group within
              Napanee, they have over 300 acres of development land and are
              making a long-term commitment to the Napanee Area. It was an easy
              commitment for the company to make given everything Napanee offers
              and its proximity to so much within a 30 km radius in either
              direction.
            </p>
            <p>
              At the core of this organization rests the skillful and inspired
              leadership of Ahsan Ashraf, Chairman and President and Warris
              Kamran, CEO.
            </p>
            <Button
              intent="submit"
              onClick={() =>
                window.open("https://bashdevelopments.com", "_blank")
              }
            >
              Visit
            </Button>
          </StyledVBox>
        </HBox>
      </VBox>
    </Section>
  );
};

export default Partners;
