const Form = () => {
  return (
    <form
      id="houzez-form"
      method="post"
      name="New Form"
      action="http://bestdevelopments.ca/wp-admin/admin-ajax.php"
    ></form>
  );
};

export default Form;
