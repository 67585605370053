import Banner from "../../components/Banner";
import Section from "../../components/Section";
import { HBox } from "../../styles/HBox";
import { theme } from "../../styles/Styles";
import { H1 } from "../../styles/Text";
import { VBox } from "../../styles/VBox";
import Community from "../HomePage/Community";

const CurrentCommunities = () => {
  return (
    <Section head="Current communities">
      <VBox style={{ gap: "2rem", marginBottom: "-20px" }}>
        <Banner
          src="/rogers.jpg"
          heading="Current Communities"
          subHeading="Communites currently existing in our portfolio"
          data-aos="zoom-out"
        />
        <HBox flexWrap="wrap" style={{ gap: "2rem" }}>
          <Community
            src="/current.jpg"
            link="https://lakesideviews.ca"
            Name="The Lakeside Views"
            Description="199 Freehold homes situated in Beaverton minutes away from the lake."
            MoreDesc={
              <p>
                Settled lakeside in the charming community of Beaverton, also
                known as the largest region in the Brock Township. Enjoy
                year-round recreational activities along the stunning rolling
                countryside, surrounded by shoreline parks and in the midst of a
                quaint suburban area. Reside alongside Lake Simcoe, a key
                destination to enjoy all four seasons and relish in outdoor
                activities including swimming, canoeing, ice fishing, camping
                and much more! This delightful family-oriented community
                contains a mixture of Bungalows, 2-Storey & Towns starting from
                the <b>$600's</b>. Don't miss out on this incredible opportunity
                to enjoy an easy life of relaxation and bliss!
                <br />
                <br />
                <a
                  style={{ color: theme.light.blue }}
                  href="https://lakesideviews.ca"
                >
                  Visit →
                </a>
              </p>
            }
          />
          <Community
            src="/bobcaygeon.jpg"
            link="https://www.3lakes.ca/"
            Name="The Stars of Bobcaygeon"
            Description="Managed by 3Lakes, we bring you a stunning new subdivision situated in the Bobcaygeon area near the lake and beautiful scenery."
            MoreDesc={
              <p>
                From the Algonquin word ‘bobcaygewanunk’ meaning ‘shallow
                rapids’, Bobcaygeon is all about water. Under an hour drive from
                the GTA, set on three islands between Pigeon Lake and Sturgeon
                Lake, Bobcaygeon in Kawartha Lakes is where families have headed
                in the summertime for over a century. Imagine living here and
                enjoying the natural splendour, outdoor recreation and active
                lifestyle all year round. Get on the water in many ways; boating
                the Trent Severn Waterway, swimming, fishing, canoeing,
                waterskiing, or relaxing on a dock or lakeside café. Enjoy life
                in Bobcaygeon today, one of Ontario’s most beautiful waterfront
                communities with a quaint, resort-town feel, right in the heart
                of cottage country. Own an elegant new home in a brand new
                community, walking distance to vibrant historic Downtown
                Bobcaygeon where you’ll find a friendly welcome and small town
                charm. All the amenities are nearby including shopping,
                antiquing, dining, medical services as well as a seasonal
                Farmer’s Market and the famous Kawartha Dairy. Follow the Stars
                to Bobcaygeon and discover the place for you.
                <br />
                <br />
                <a
                  style={{ color: theme.light.blue }}
                  href="https://www.3lakes.ca/"
                >
                  Visit →
                </a>
              </p>
            }
          />
          <Community
            src="/sheridan.jpg"
            Name="Springside Meadows"
            link="https://springsidemeadows.ca"
            Description="An exceptional new subdivision offering consisting of 486 units in the Greater Napanee Area which is just 20 minutes away from Kingston."
            MoreDesc={
              <p>
                Springside Meadows is a new home community offering over 15
                acres of parks, ponds and passive green open spaces. A community
                for your lifestyle, less than 1km to the vibrant shops,
                restaurants, parks, riverside trails and choice fishing in the
                town of Napanee. A location that is almost 30km’s to either the
                City of Kingston, Belleville or to Prince Edward County (famous
                wineries and beaches).
                <br />
                <br />
                <a
                  style={{ color: theme.light.blue }}
                  href="https://springsidemeadows.ca"
                >
                  Visit →
                </a>
              </p>
            }
          />
        </HBox>
      </VBox>
    </Section>
  );
};

export default CurrentCommunities;
